// extracted by mini-css-extract-plugin
export var alignLeft = "t_p5 g_gm g_cK g_fy";
export var alignCenter = "t_cS g_gn g_cH g_fz";
export var alignRight = "t_p6 g_gp g_cL g_fB";
export var testimonialsContainer = "t_rw g_fW";
export var testimonialsContainerFull = "t_rx g_fT";
export var testimonialsMainHeader = "t_kg g_kg g_bD g_dz";
export var testimonialsComponentParagraph = "t_kj g_kj";
export var testimonialsComponentSmall = "t_kh g_kh";
export var testimonialsComponentsWrapper = "t_kc g_kc g_bG g_bD g_dy g_c6 g_c8";
export var testimonialsComponentsWrapperDesign2 = "t_kd g_kd g_bG g_bD g_dy g_c6 g_c8 g_cG";
export var testimonialsComponentsWrapperDesign3 = "t_kf g_kf g_bG g_bD g_bP g_c8 g_cX g_cD g_cM g_cS g_cH";
export var testimonialsBackgroundColor = "t_ry g_f5";
export var colEqualHeight = "t_rz g_cD";
export var testimonialsColumnWrapper = "t_kk g_kk g_c6";
export var testimonialsImageWrapper = "t_j8 g_j8 g_bD g_b5";
export var testimonialsImageWrapperDesign2 = "t_j9 g_j9 g_by g_b5 g_cB";
export var testimonialsImageWrapperDesign3 = "t_kb g_kb g_b5 g_cD";
export var design2TextWrapper = "t_rB g_cG g_cS g_cM";
export var design3 = "t_rC g_dH g_bP";
export var imageDesign2 = "t_rD";
export var SmallSmall = "t_rF y_rF y_r7 y_sv";
export var SmallNormal = "t_rG y_rG y_r7 y_sw";
export var SmallLarge = "t_rH y_rH y_r7 y_ss";
export var exceptionWeight = "t_rv y_sz";