// extracted by mini-css-extract-plugin
export var sectionMediaWrapper = "q_qt g_cD g_dw";
export var alignLeft = "q_p5 g_gm g_cK g_fy";
export var alignCenter = "q_cS g_gn g_cH g_fz";
export var alignRight = "q_p6 g_gp g_cL g_fB";
export var alignColumnLeft = "q_qv g_gq g_cR g_fy";
export var alignColumnCenter = "q_qw g_gr g_cS g_fz";
export var alignColumnRight = "q_qx g_gs g_cT g_fB";
export var featuresContainer = "q_qy g_fW";
export var featuresContainerFull = "q_qz g_fT";
export var featuresComponentWrapper = "q_jD g_jD g_dy";
export var compContentWrapper = "q_qB g_jF g_fb g_bP";
export var featuresTextBlockWrapper = "q_jK g_jK";
export var featuresMainHeader = "q_jB g_jB g_bD g_dv";
export var featuresSubHeader = "q_jC g_jC g_bD";
export var featuresComponentHeader = "q_jG g_jG g_bD g_dv";
export var featuresComponentParagraph = "q_jH g_jH g_bD";
export var featuresComponentWrapperRow = "q_qC g_bP";
export var featuresBlockWrapper = "q_jJ g_jJ";
export var btnWrapper = "q_p g_cD g_dn";
export var btnWrapperCards = "q_qD g_cD g_dh";
export var cardsWrapper = "q_qF";