// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "x_gN g_gN g_cD g_cL g_cM";
export var navbarDividedRight = "x_gP g_gP g_cD g_cM";
export var menuLeft = "x_rN g_gL g_cD g_fz g_cS g_cM";
export var menuRight = "x_rP g_gL g_cD g_fz g_cS g_cM";
export var menuCenter = "x_rQ g_gM g_gL g_cD g_fz g_cS g_bD g_cH g_cM";
export var menuDivided = "x_n5 g_gM g_gL g_cD g_fz g_cS g_bD g_cH";
export var navbarItem = "x_n6 g_cB";
export var navbarLogoItemWrapper = "x_gT g_gT g_cG g_cS";
export var burgerToggleVisibleOpen = "x_rR g_g7 g_cC g_b5 g_cw";
export var burgerToggleVisible = "x_rS g_g7 g_cC g_b5 g_cw";
export var burgerToggle = "x_rT g_g7 g_cC g_b5 g_cw g_b5";
export var burgerToggleOpen = "x_rV g_g7 g_cC g_b5 g_cw g_b9";
export var burgerInput = "x_rW g_gY g_bD g_bP g_cC g_dg g_fp g_cl g_b6 g_cg g_cf g_cb g_ch";
export var burgerLine = "x_rX g_gX";
export var burgerMenuLeft = "x_rY g_g2 g_g1 g_gZ g_g0 g_b6 g_dg g_fq g_cw g_fz";
export var burgerMenuRight = "x_rZ g_g3 g_g1 g_gZ g_g0 g_b6 g_dg g_fq g_cw g_fz";
export var burgerMenuCenter = "x_r0 g_g4 g_g1 g_gZ g_g0 g_b6 g_dg g_fq g_cw g_fz";
export var burgerMenuDivided = "x_r1 g_g2 g_g1 g_gZ g_g0 g_b6 g_dg g_fq g_cw g_fz";
export var secondary = "x_r2 g_cG g_cS";
export var staticBurger = "x_r3";
export var menu = "x_r4";
export var navbarDividedLogo = "x_r5";
export var nav = "x_r6";