// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_qG g_hp g_dw";
export var heroHeaderCenter = "r_hq g_hq g_dw g_fz";
export var heroHeaderRight = "r_hr g_hr g_dw g_fB";
export var heroParagraphLeft = "r_qH g_hl g_dz";
export var heroParagraphCenter = "r_hm g_hm g_dz g_fz";
export var heroParagraphRight = "r_hn g_hn g_dz g_fB";
export var heroBtnWrapperLeft = "r_qJ g_p g_f0 g_bD g_cD g_cK";
export var heroBtnWrapperCenter = "r_qK g_f1 g_f0 g_bD g_cD g_cH";
export var heroBtnWrapperRight = "r_qL g_f2 g_f0 g_bD g_cD g_cL";
export var overlayBtnWrapper = "r_qM g_hk g_b6 g_cf g_cg g_ch g_cr g_dg";
export var design4 = "r_qN g_hj g_b6 g_cf g_cg g_cr";
export var heroExceptionSmall = "r_qP y_qP";
export var heroExceptionNormal = "r_qQ y_qQ";
export var heroExceptionLarge = "r_qR y_qR";
export var Title1Small = "r_qS y_qS y_r7 y_r8";
export var Title1Normal = "r_qT y_qT y_r7 y_r9";
export var Title1Large = "r_qV y_qV y_r7 y_sb";
export var BodySmall = "r_qW y_qW y_r7 y_sr";
export var BodyNormal = "r_qX y_qX y_r7 y_ss";
export var BodyLarge = "r_qY y_qY y_r7 y_st";