// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_qZ g_gm g_cK g_fy";
export var alignLeft = "s_p5 g_gm g_cK g_fy";
export var alignDiscCenter = "s_q0 g_gn g_cH g_fz";
export var alignCenter = "s_cS g_gn g_cH g_fz";
export var alignDiscRight = "s_q1 g_gp g_cL g_fB";
export var alignRight = "s_p6 g_gp g_cL g_fB";
export var footerContainer = "s_q2 g_fW g_cZ";
export var footerContainerFull = "s_q3 g_fT g_cZ";
export var footerHeader = "s_kZ g_kZ";
export var footerTextWrapper = "s_q4 g_bD";
export var footerDisclaimerWrapper = "s_k4 g_k4 g_dn";
export var badgeWrapper = "s_q5 g_bD g_cD g_cS g_cJ g_cM";
export var footerDisclaimerRight = "s_q6 undefined";
export var footerDisclaimerLeft = "s_q7 undefined";
export var verticalTop = "s_q8 g_cD g_cM g_cR g_cP";
export var firstWide = "s_q9";
export var disclaimer = "s_rb g_cD g_cM";
export var socialDisclaimer = "s_rc";
export var left = "s_rd";
export var wide = "s_rf g_dC";
export var right = "s_rg g_cN";
export var line = "s_gh g_gj g_dy";
export var badgeDisclaimer = "s_rh g_cG g_cS g_cM";
export var badgeContainer = "s_rj g_cD g_cL g_cS";
export var badge = "s_rk";
export var padding = "s_rl g_fb";
export var end = "s_rm g_cL";
export var linkWrapper = "s_rn g_fF";
export var link = "s_v g_fF";
export var colWrapper = "s_rp g_dB";
export var consent = "s_f g_f g_cG g_cS";
export var media = "s_rq g_cB g_fC";
export var itemRight = "s_rr";
export var itemLeft = "s_rs";
export var itemCenter = "s_rt";
export var exceptionWeight = "s_rv y_sz";