// extracted by mini-css-extract-plugin
export var carouselContainer = "G_vn g_bD g_bP g_C g_b5";
export var carouselContainerCards = "G_vp G_vn g_bD g_bP g_C g_b5";
export var carouselContainerSides = "G_vq g_bD g_bP g_b5";
export var prevCarouselItem = "G_vr g_bD g_bP g_b6 g_br";
export var prevCarouselItemL = "G_vs G_vr g_bD g_bP g_b6 g_br";
export var moveInFromLeft = "G_vt";
export var prevCarouselItemR = "G_vv G_vr g_bD g_bP g_b6 g_br";
export var moveInFromRight = "G_vw";
export var selectedCarouselItem = "G_vx g_bD g_bP g_b5 g_C";
export var selectedCarouselItemL = "G_vy G_vx g_bD g_bP g_b5 g_C";
export var selectedCarouselItemR = "G_vz G_vx g_bD g_bP g_b5 g_C";
export var nextCarouselItem = "G_vB g_bD g_bP g_b6 g_br";
export var nextCarouselItemL = "G_vC G_vB g_bD g_bP g_b6 g_br";
export var nextCarouselItemR = "G_vD G_vB g_bD g_bP g_b6 g_br";
export var arrowContainer = "G_vF g_bP g_b6 g_fF g_cp g_cb g_c4";
export var prevArrowContainer = "G_vG G_vF g_bP g_b6 g_fF g_cp g_cb g_c4";
export var prevArrowContainerHidden = "G_vH G_vG G_vF g_bP g_b6 g_fF g_cp g_cb g_c4 g_br";
export var nextArrowContainer = "G_vJ G_vF g_bP g_b6 g_fF g_cp g_cb g_c4";
export var carouselContainerProducts = "G_vK";
export var nextArrowContainerHidden = "G_vL G_vJ G_vF g_bP g_b6 g_fF g_cp g_cb g_c4 g_br";
export var arrow = "G_lp g_b6";
export var prevArrow = "G_vM G_lp g_b6";
export var nextArrow = "G_vN G_lp g_b6";
export var carouselIndicatorContainer = "G_vP g_bD g_fz g_cp g_cD g_cH g_cS g_cM g_b6";
export var btnsContainer = "G_vQ g_bD g_cD g_cJ";
export var carouselText = "G_vR g_ft g_cD g_cH g_cS g_c4 g_fF g_dH";
export var carouselTextInactive = "G_vS G_vR g_ft g_cD g_cH g_cS g_c4 g_fF g_dH";
export var indicator = "G_vT g_db";
export var carouselIndicator = "G_vV G_vT g_db";
export var carouselIndicatorSelected = "G_vW G_vT g_db";
export var arrowsContainerTopRight = "G_vX g_b6 g_cp g_cG";
export var arrowsContainerBottomLeft = "G_vY g_b6 g_cp g_cG";
export var arrowsContainerSides = "G_vZ g_b6 g_cp g_cG";
export var smallArrowsBase = "G_v0 g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var smallArrowContainer = "G_v1 G_v0 g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var smallArrowContainerPrev = "G_v2 G_v1 G_v0 g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var smallArrowContainerSmall = "G_v3 G_v0 g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var smallArrowContainerPrevSmall = "G_v4 G_v3 G_v0 g_cX g_cD g_cH g_cS g_c4 g_fF g_dH";
export var icon = "G_pZ";
export var iconSmall = "G_v5";
export var multipleWrapper = "G_v6 g_cG g_cJ g_C";
export var multipleImage = "G_v7 g_cG";
export var sidesPrevContainer = "G_v8 G_v1 G_v0 g_cX g_cD g_cH g_cS g_c4 g_fF g_dH g_gl g_b6 g_cp g_dH";
export var sidesNextContainer = "G_v9 G_v1 G_v0 g_cX g_cD g_cH g_cS g_c4 g_fF g_dH g_gl g_b6 g_cp g_dH";